import axios from "axios"
import ErrorMessages from "../ErrorMessages"

const HTTP_METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
}

class HttpClient {
  constructor() {
    this._axios = axios.create({
      baseURL: process.env.API_BASE_URL,
    })
  }

  setAuthorization(options) {
    if (!options.headers) options.headers = {}
    options.headers["X-API-KEY"] = `${process.env.API_KEY}`
  }

  async doRequest(method, uri, query, data, options, setAuthorization) {
    if (!options) options = {}
    if (query) options.params = query
    if (setAuthorization) this.setAuthorization(options)
    try {
      let response
      switch (method) {
        case HTTP_METHOD.GET:
          response = await this._axios.get(uri, options)
          break
        case HTTP_METHOD.POST:
          response = await this._axios.post(uri, data, options)
          break
        case HTTP_METHOD.PUT:
          response = await this._axios.put(uri, data, options)
          break
        case HTTP_METHOD.DELETE:
          response = await this._axios.delete(uri, options)
          break
        default:
          console.log("Unsupport http method")
      }
      return response.data
    } catch (error) {
      let message
      if (error.response) {
        const { data, status } = error.response
        console.log("Response error -> status:", status, ", data:", data)
        message = ErrorMessages[data.code]
      } else if (error.request) {
        console.log(error.request)
        message = "ไม่มีการตอบสนองจากเซิฟเวอร์"
      } else {
        console.log(error.message)
      }
      throw new Error(message || "เกิดข้อผิดพลาด")
    }
  }

  async get(uri, query, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.GET,
      uri,
      query,
      null,
      null,
      setAuthorization
    )
  }

  async post(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.POST,
      uri,
      null,
      data,
      null,
      setAuthorization
    )
  }

  async put(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.PUT,
      uri,
      null,
      data,
      null,
      setAuthorization
    )
  }

  async delete(uri, query, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.DELETE,
      uri,
      query,
      null,
      null,
      setAuthorization
    )
  }

  async postMultipartForm(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.POST,
      uri,
      null,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      setAuthorization
    )
  }

  async postFormUrlEncoded(uri, data, setAuthorization = true) {
    return this.doRequest(
      HTTP_METHOD.POST,
      uri,
      null,
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
      setAuthorization
    )
  }
}

const httpClient = new HttpClient()
export default httpClient
