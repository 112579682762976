import { extendObservable,toJS } from 'mobx'
import http from '../utils/http'

export class BookingStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      info: {

      }
    })
  }

  getInfoJS() {
    return toJS(this.info)
  }

  async submitBooking(data) {
    try {
      const response = await http.post(`/bookings`, data);
      if (response) {
        return response
      }
    } catch (error) {
      throw error;
    }
  }

  async getBookingBID(bid) {
    try {
      const query = {
        show_payment: true,
        show_house:true
      }
      const response = await http.get(`/bookings/${bid}/bid`,query);
      if (response) {
        this.info = response || {}
        return response
      }
    } catch (error) {
      throw error;
    }

    // this.info = {
    //   bid : 'bid',
    //   firstname : 'ชยากร',
    //   lastname : 'เจนพิทักษ์',
    //   email : 'bankj123@gmail.com',
    //   mobile : '0866611007',
    //   checkIn : new Date(),
    //   checkOut : addDays(new Date(),1),
    //   adults : 2,
    //   price : 10000,
    //   totalPrice : 10000
    // }
    // return this.info
  }


}

const bookingStore = new BookingStore()
export default bookingStore
