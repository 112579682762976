// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/AboutUs/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-booking-detail-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/Booking/detail.js" /* webpackChunkName: "component---src-pages-booking-detail-js" */),
  "component---src-pages-booking-index-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/Booking/index.js" /* webpackChunkName: "component---src-pages-booking-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/ContactUs/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-customer-review-index-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/CustomerReview/index.js" /* webpackChunkName: "component---src-pages-customer-review-index-js" */),
  "component---src-pages-home-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-house-index-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/House/index.js" /* webpackChunkName: "component---src-pages-house-index-js" */),
  "component---src-pages-index-js": () => import("/Users/chayakornjenpitak/Documents/site/just-rent/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

