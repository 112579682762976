import house from './HouseStore';
import search from './SearchStore';
import contactUsStore from './ContactUsStore'
import booking from './BookingStore'
import config from './ConfigStore'

export default {
  house,
  search,
  contactUsStore,
  booking,
  config
};
