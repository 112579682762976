import { extendObservable,toJS } from 'mobx'
import http from '../utils/http'

export class BookingStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      configs: { }
    })
  }

  getConfigJS() {
    return toJS(this.configs)
  }

  async getConfig(id) {
    try {
      const response = await http.get(`/configs/${id}`);
      if (response) {
        this.configs = response || {}
        return response
      }
    } catch (error) {
      throw error;
    }
  }


}

const bookingStore = new BookingStore()
export default bookingStore
