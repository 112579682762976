import { extendObservable } from 'mobx'
import http from '../utils/http'

export class ContactUsStore {
  constructor() {
    extendObservable(this, {
      loading: false,
    })
  }

  async saveContactUs(data) {
    try {
      this.loading = true;
      const response = await http.post(`/contactus`, data);

      return response;
    } catch (error) {
      // throw error;
      console.log('## Error when call AddLocation(): ', error);
      throw new Error(error.response.data.code);
    } finally {
      this.loading = false;
    }
  }

  
}

const contactUsStore = new ContactUsStore()
export default contactUsStore
