import { extendObservable, toJS } from "mobx"
import { navigate } from "@reach/router"
import startOfDay from "date-fns/startOfDay"
import addDays from "date-fns/addDays"
import format from "date-fns/format"
import http from "../utils/http"
import { minByProp, maxByProp, halfDay } from "../utils/common"

export class HouseStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      locations: [],
      houses: [],
      checkIn: startOfDay(new Date()),
      checkOut: addDays(startOfDay(new Date()), 1),
      adults: 2,
      adultsKid: 0,
      locationSearch: "",
    })
  }

  isLoading() {
    return toJS(this.loading)
  }
  getLocationsJS() {
    return toJS(this.locations)
  }
  getHousesJS() {
    return toJS(this.houses)
  }
  setHouses(houses) {
    this.houses = houses
  }

  async searchHouse(
    checkIn,
    checkOut,
    adults,
    adultsKid,
    location,
    page,
    status = false
  ) {
    try {
      if (status) {
        let now = new Date()
        now.setMinutes(now.getMinutes() + 360) // timestamp
        const exp = new Date(now).getTime() // Date object
        await localStorage.setItem("searchExp", exp)
      }
      this.checkIn = format(checkIn, "yyyy-MM-dd")
      this.checkOut = format(checkOut, "yyyy-MM-dd")
      this.adults = adults
      this.adultsKid = adultsKid

      this.locationSearch = location
      const query = {
        locations: location,
        adults: +adults + +adultsKid,
        check_in: halfDay(checkIn, "start"),
        check_out: halfDay(checkOut, "end"),
        limit: 500,
      }
      const response = await http.get(`/houses/search`, query)
      if (response) {
        const houses = response.data || []
        houses.map(val => {
          let price_detail = {}
          price_detail.price = maxByProp(val.prices, "price")
          price_detail.special_price = minByProp(val.prices, "special_price")
          price_detail.price_per_person = maxByProp(
            val.prices,
            "price_per_person"
          )
          val.price_detail = price_detail
          val.image_is_highlight = (val.house_images || []).find(
            f => f.is_highlight
          )
          if (!val.image_is_highlight) {
            val.image_is_highlight = val.house_images[0] || {
              url: `${process.env.AWS_S3_URL}/assets/img-null.png`,
            }
          }

          return val
        })
        this.houses = houses
        const search = {
          checkIn: halfDay(checkIn, "start"),
          checkOut: halfDay(checkOut, "end"),
          adults,
          adultsKid,
          location,
          houses: response.data || [],
          timestamp: new Date().getTime(),
        }
        await localStorage.setItem("search", JSON.stringify(search))
        if (page !== "home") {
          navigate(`/`)
        } else {
          return houses
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async fetchLocations() {
    try {
      const response = await http.get(`/locations`)
      if (response) {
        this.locations = response || []
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const houseStore = new HouseStore()
export default houseStore
