import React from "react"
import { Provider } from "mobx-react"
import stores from "./stores"

import "bootstrap/dist/css/bootstrap.min.css"

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css" // Import the CSS
config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

export default ({ element }) => {
  return <Provider {...stores}>{element}</Provider>
}
