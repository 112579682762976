import moment from "moment"

export function numberWithCommas(x) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function sortByProp(items, prop, direction) {
  const compareFunc = (direction && direction.toLowerCase() === 'desc')
    ? (a, b) => b[prop] - a[prop]
    : (a, b) => a[prop] - b[prop];
  return items.slice().sort(compareFunc);
}

export function minByProp(items, prop) {
  return Math.min(...items.map(i => i[prop]))
}

export function maxByProp(items, prop) {
  return Math.max(...items.map(i => i[prop]))
}

export function halfDay(date, task) {
  const time = moment(date);
  if (task === 'start') {
    time.set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
  } else {
    time.set({ hour: 11, minute: 59, second: 0, millisecond: 0 })
  }
  time.toISOString()
  time.format()
  return time
}

export function checkBetween(date, start, end) {
  const dateFormat = moment(date).format('DD/MM/YYYY');
  const startFormat = moment(start).format('DD/MM/YYYY');
  const endFormat = moment(end).format('DD/MM/YYYY');
  const startArray = startFormat.split("/");
  const endArray = endFormat.split("/");
  const dateArray = dateFormat.split("/");
  const from = new Date(startArray[2], parseInt(startArray[1]) - 1, startArray[0]);
  const to = new Date(endArray[2], parseInt(endArray[1]) - 1, endArray[0]);
  const check = new Date(dateArray[2], parseInt(dateArray[1]) - 1, dateArray[0]);
  if (check >= from && check < to) {
    return true
  } else {
    return false
  }
}
